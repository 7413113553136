<template>
  <div class="super-table">
    <el-card
      shadow="never"
      :body-style="{ display: 'flex', 'justify-content': 'space-between' }"
    >
      <div class="table-actions">
        <el-button
          type="primary"
          v-if="allowCreate"
          @click="showCreateDialog = true"
          >添加</el-button
        >
        <!-- <el-button
          type="default"
          v-if="allowImport"
          @click="showImportDialog = true"
          >导入</el-button
        >
        <el-button type="default" v-if="allowExport">导出</el-button> -->
        <slot name="actions"></slot>
      </div>
      <slot name="search"></slot>
    </el-card>
    <el-card shadow="never" style="margin: 16px 0" :body-style="{ padding: 0 }">
      <el-table :data="data" @selection-change="handleSelectionChange">
        <slot></slot>
      </el-table>
    </el-card>
    <el-card shadow="never" style="text-align: right">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="sizeChange"
      ></el-pagination>
    </el-card>

    <el-drawer title="添加" :visible.sync="showCreateDialog">
      <slot name="create"></slot>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    allowCreate: {
      type: Boolean,
      default: true,
    },
    allowImport: {
      type: Boolean,
      default: false,
    },
    allowExport: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCreateDialog: false,
      page: 1,
    };
  },
  methods: {
    closeCreateDialog() {
      this.showCreateDialog = false;
    },
    pageChange(val) {
      this.$emit("page-change", val);
    },
    sizeChange(val) {
      this.$emit("size-change", val);
    },
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.query-form {
  .el-form-item {
    margin: 0 0 0 8px;
  }
}

/deep/ :focus {
  outline: 0;
}
</style>
