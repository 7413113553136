<template>
  <!-- 应用管理修改 -->
  <el-drawer
    :visible.sync="visible"
    :before-close="handleClose"
    title="修改应用信息"
    :destroy-on-close="true"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      label-position="left"
      style="margin: 0 20px"
      v-loading="loading"
      @submit.native.prevent
    >
      <el-form-item label="应用名称" prop="name">
        <el-input placeholder="请输入..." v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="APP Kye" prop="appKey">
        <el-input placeholder="请输入..." v-model="form.appKey"></el-input>
      </el-form-item>
      <el-form-item label="Secret Key" prop="secretKey">
        <el-input placeholder="请输入..." v-model="form.secretKey"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" :loading="submiting"
          >提交</el-button
        >
        <el-button type="default" @click="visible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      submiting: false,
      form: {
        name: "",
        status: "",
      },
      rules: {
        name: {
          required: true,
          message: "请输入应用名称",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    edit(id) {
      this.visible = true;
      this.loading = true;
      this.$api.app
        .show(id)
        .then((res) => {
          this.form = res;
        })
        .catch((error) => {
          this.$message({ messge: error, type: "danger" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.submiting = true;
      this.$api.app
        .update(this.form.id, this.form)
        .then(() => {
          this.$message({ message: "提交成功", type: "success" });
          this.visible = false;
          this.$emit('on-update')
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        })
        .finally(() => {
          this.submiting = false;
        });
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
};
</script>


<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}
</style>