<template>
  <!-- 应用管理 -->
  <div class="wrapper">
    <el-alert type="success" style="margin: 0 0 16px 0"
      >表示一个第三方应用程序</el-alert
    >

    <super-table
      ref="superTable"
      :allow-create="true"
      :allow-import="true"
      :allow-export="true"
      :data="list"
      :total="total"
      :page-size="queryForm.size"
      @page-change="onPageChange"
      @size-change="onSizeChange"
    >
      <el-form
        :inline="true"
        class="query-form"
        slot="search"
        :model="queryForm"
      >
        <el-form-item prop="keyword">
          <el-input placeholder="关键词" v-model="queryForm.keyword"></el-input>
        </el-form-item>
        <!-- <el-form-item style="padding: 0; margin: 0 2px">
          <el-select placeholder="请选择状态" v-model="queryForm.status" clearable>
            <el-option value="1" label="正常"></el-option>
            <el-option value="2" label="停用"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="default" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>

      <el-form
        :model="createForm"
        :rules="createRules"
        slot="create"
        label-position="left"
        label-width="100px"
        style="margin: 0 16px"
        @submit.native.prevent
      >
        <el-form-item label="应用名称" prop="name">
          <el-input
            placeholder="请输入..."
            v-model="createForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="APP Key" prop="appKey">
          <el-input
            placeholder="请输入..."
            v-model="createForm.appKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="Secret Key" prop="secretKey">
          <el-input
            placeholder="请输入..."
            v-model="createForm.secretKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="createForm.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="create" native-type="submit"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <el-table-column type="selection"></el-table-column>
      <el-table-column label="编号" prop="id" width="100" align="center"></el-table-column>
      <el-table-column label="应用名称" prop="name" align="center"></el-table-column>
      <el-table-column label="APP Key" prop="appKey" align="center"></el-table-column>
      <el-table-column label="Secret Key" prop="secretKey" align="center"></el-table-column>
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">正常</el-tag>
          <el-tag v-if="scope.row.status === 0" type="warning">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="text" class="action" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" class="action" @click="remove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </super-table>

    <edit ref="editDialog" @on-update="search"></edit>
  </div>
</template>

<script>
import SuperTable from "@/components/SuperTable/SuperTable";
import edit from "./edit";
export default {
  components: {
    SuperTable,
    edit,
  },
  data() {
    return {
      queryForm: {
        keyword: "",
        page: 1,
        size: 10,
      },
      createForm: {
        name: "",
        appKey: "",
        secretKey: "",
        status: 1,
      },
      createRules: {
        name: { required: true, message: "请输入...", trigger: "blur" },
      },
      list: [],
      total: 0,
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.$api.app
        .search(this.queryForm)
        .then((res) => {
          console.log(res)
          this.list = res.content;
          this.queryForm.size = res.pageable.size;
          this.total = parseInt(res.totalElements);
        })
        .catch((error) => console.log(error));
    },
    create() {
      this.$api.app
        .create(this.createForm)
        .then(() => {
          this.$refs.superTable.closeCreateDialog();
          this.search();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(item) {
      this.$refs.editDialog.edit(item.id);
    },
    remove(item) {
      this.$confirm("确定要删除该项目吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.app
            .destroy(item.id)
            .then((res) => {
              console.log(res);
              this.search();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    onPageChange(val) {
      this.queryForm.page = val;
      this.search();
    },
    onSizeChange(val) {
      this.queryForm.size = val;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}
</style>
